var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.device + "-event",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_en.jpg")
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/banners_ir_cn.jpg")
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "companyBlock",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("投资者关系")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("INVESTORS")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("投資者關係")]) : _vm._e()]), _c("div", {
    staticClass: "companyInfo clearfix"
  }, [_c("ul", {
    staticClass: "tMenuLeft",
    class: _vm.language == 2 ? "tMenuLeftEn" : ""
  }, [_vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 1 || !_vm.language ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.name)), _c("em", [_vm._v(">")])])]) : _vm._e();
  }), _vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 2 ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameEn)), _c("em", [_vm._v(">")])])]) : _vm._e();
  }), _vm._l(_vm.irMenuData, function (items, index) {
    return _vm.language == 3 ? _c("router-link", {
      attrs: {
        to: items.path
      }
    }, [_c("li", {
      class: _vm.activeMenu == items.value ? "active" : ""
    }, [_vm._v(_vm._s(items.nameTr)), _c("em", [_vm._v(">")])])]) : _vm._e();
  })], 2), _c("div", {
    staticClass: "comContentRight",
    class: _vm.language == 2 ? "comContentRightEn" : "",
    staticStyle: {
      "padding-bottom": "10em"
    }
  }, [_vm.language == 1 ? _c("div", {
    staticClass: "searchBox"
  }, [_c("select", {
    on: {
      change: function ($event) {
        return _vm.change($event);
      }
    }
  }, [_c("option", {
    attrs: {
      value: "0"
    }
  }, [_vm._v("全部")]), _c("option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("公告及通告")]), _c("option", {
    attrs: {
      value: "5"
    }
  }, [_vm._v("委任代表表格")]), _c("option", {
    attrs: {
      value: "6"
    }
  }, [_vm._v("月报表")]), _c("option", {
    attrs: {
      value: "7"
    }
  }, [_vm._v("翌日披露报表")]), _c("option", {
    attrs: {
      value: "8"
    }
  }, [_vm._v("财务报表/环境、社会及管制材料")]), _c("option", {
    attrs: {
      value: "10"
    }
  }, [_vm._v("通函")])]), _c("el-date-picker", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dateShow,
      expression: "dateShow"
    }],
    attrs: {
      type: "daterange",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.searchTimeDate,
      callback: function ($$v) {
        _vm.searchTimeDate = $$v;
      },
      expression: "searchTimeDate"
    }
  }), _c("button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dateShow,
      expression: "dateShow"
    }],
    on: {
      click: _vm.search
    }
  }, [_vm._v("搜索")]), _c("a", {
    attrs: {
      clsss: "mail"
    },
    on: {
      click: _vm.open
    }
  }, [_vm._v("@ 邮件订阅")])], 1) : _vm._e(), _vm.language == 2 ? _c("div", {
    staticClass: "searchBox"
  }, [_c("select", {
    on: {
      change: function ($event) {
        return _vm.change($event);
      }
    }
  }, [_c("option", {
    attrs: {
      value: "0"
    }
  }, [_vm._v("All")]), _c("option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("Announcements and Notices")]), _c("option", {
    attrs: {
      value: "5"
    }
  }, [_vm._v("Proxy Forms")]), _c("option", {
    attrs: {
      value: "6"
    }
  }, [_vm._v("Monthly Returns")]), _c("option", {
    attrs: {
      value: "7"
    }
  }, [_vm._v("Next Day Disclosure Returns")]), _c("option", {
    attrs: {
      value: "8"
    }
  }, [_vm._v("Financial Statements/ESG Information")]), _c("option", {
    attrs: {
      value: "10"
    }
  }, [_vm._v("Circulars")])]), _c("el-date-picker", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dateShow,
      expression: "dateShow"
    }],
    attrs: {
      type: "daterange",
      "range-separator": "To",
      "start-placeholder": "Start Date",
      "end-placeholder": "End Date",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.searchTimeDate,
      callback: function ($$v) {
        _vm.searchTimeDate = $$v;
      },
      expression: "searchTimeDate"
    }
  }), _c("button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dateShow,
      expression: "dateShow"
    }],
    on: {
      click: _vm.search
    }
  }, [_vm._v("Search")]), _c("a", {
    attrs: {
      clsss: "mail"
    },
    on: {
      click: _vm.open
    }
  }, [_vm._v("@ Mail Subscription")])], 1) : _vm._e(), _vm.language == 3 ? _c("div", {
    staticClass: "searchBox"
  }, [_c("select", {
    on: {
      change: function ($event) {
        return _vm.change($event);
      }
    }
  }, [_c("option", {
    attrs: {
      value: "0"
    }
  }, [_vm._v("全部")]), _c("option", {
    attrs: {
      value: "4"
    }
  }, [_vm._v("公告及通告")]), _c("option", {
    attrs: {
      value: "5"
    }
  }, [_vm._v("委任代表表格")]), _c("option", {
    attrs: {
      value: "6"
    }
  }, [_vm._v("月報表")]), _c("option", {
    attrs: {
      value: "7"
    }
  }, [_vm._v("翌日披露報表")]), _c("option", {
    attrs: {
      value: "8"
    }
  }, [_vm._v("財務報表/環境、社會及管制資料")]), _c("option", {
    attrs: {
      value: "10"
    }
  }, [_vm._v("通函")])]), _c("el-date-picker", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dateShow,
      expression: "dateShow"
    }],
    attrs: {
      type: "daterange",
      "range-separator": "至",
      "start-placeholder": "開始日期",
      "end-placeholder": "結束日期",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.searchTimeDate,
      callback: function ($$v) {
        _vm.searchTimeDate = $$v;
      },
      expression: "searchTimeDate"
    }
  }), _c("button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.dateShow,
      expression: "dateShow"
    }],
    on: {
      click: _vm.search
    }
  }, [_vm._v("蒐索")]), _c("a", {
    attrs: {
      clsss: "mail"
    },
    on: {
      click: _vm.open
    }
  }, [_vm._v("@ 郵件訂閱")])], 1) : _vm._e(), _c("ul", {
    attrs: {
      id: "noticeBox"
    }
  }, _vm._l(_vm.noticeList, function (item, index) {
    return _c("a", {
      key: index,
      attrs: {
        href: _vm.title + item.fileSrc,
        target: "_blank"
      }
    }, [_c("li", [_c("p", {
      staticClass: "p1"
    }, [_vm._v(_vm._s(item.fileName))]), _c("span"), _c("p", {
      staticClass: "p2"
    }, [_vm._v(_vm._s(item.createTime))])])]);
  }), 0)])])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLpayer,
      expression: "showLpayer"
    }],
    staticClass: "videomodel"
  }, [_c("div", {
    staticClass: "videomodel-wrapper"
  }, [_c("a", {
    staticClass: "videomodel-close",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: _vm.closePlayer
    }
  }, [_c("i", {
    staticClass: "el-icon-close"
  })]), _c("el-form", {
    ref: "form",
    attrs: {
      "label-position": _vm.labelPosition,
      rules: _vm.rules,
      model: _vm.form
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "电子邮箱/E-mail",
      prop: "mail"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.email,
      callback: function ($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "姓名/Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.userName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "userName", $$v);
      },
      expression: "form.userName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "手机/Mobile"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.phone,
      callback: function ($$v) {
        _vm.$set(_vm.form, "phone", $$v);
      },
      expression: "form.phone"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "公司/Company"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.departName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "departName", $$v);
      },
      expression: "form.departName"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function ($event) {
        return _vm.submitForm("form");
      }
    }
  }, [_vm._v("确认/Enter")])], 1)], 1)], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };