import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {
      labelPosition: 'right',
      form: {
        email: '',
        userName: '',
        phone: '',
        departName: ''
      },
      rules: {
        email: [{
          required: true,
          message: '请输入电子邮箱',
          trigger: 'blur'
        }]
      },
      title: this.$api.api_title,
      searchTimeDate: '',
      noticeList: [],
      fileType: 0,
      start: null,
      end: null,
      dateShow: false,
      showLpayer: false
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      return route.name;
    },
    ...mapState({
      irMenuData: state => state.irMenuData,
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {},
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    open() {
      this.showLpayer = true;
    },
    closePlayer() {
      this.showLpayer = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$http.post(this.$api.email_SubscribeMail, this.form).then(res => {
            if (res.code == 0) {
              console.log(this.form);
              this.$message.success('订阅成功');
              this.showLpayer = false;
            }
          });
        }
      });
    },
    change(event) {
      this.fileType = event.target.value; //获取option对应的value值
      if (event.target.value == 0) {
        this.dateShow = false;
        this.getData(); //选择全部后获取数据
      } else {
        this.dateShow = true;
        this.noticeList = []; //选择其他选项情况dom
      }
    },
    getData() {
      this.$http.post(this.$api.investment_postFileSearch, {
        fileType: this.fileType,
        start: this.searchTimeDate[0],
        end: this.searchTimeDate[1]
      }).then(res => {
        if (res.code == 0) {
          if (res.data) {
            if (this.$store.state.language == 2) {
              if (res.data.en != 0) {
                this.noticeList = res.data.en;
              } else {
                this.$message.error('No data yet!');
              }
            } else if (this.$store.state.language == 3) {
              if (res.data.traditional != 0) {
                this.noticeList = res.data.traditional;
              } else {
                this.$message.error('暫無數據！');
              }
            } else if (this.$store.state.language == 1) {
              if (res.data.cn.length != 0) {
                this.noticeList = res.data.cn;
              } else {
                this.$message.error('暂无数据！');
              }
            }
          }
        }
      });
    },
    search() {
      if (this.dateShow) {
        if (this.searchTimeDate) {
          this.getData();
        } else {
          this.$message.error('请输入日期');
        }
      }
    }
  }
};